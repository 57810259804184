import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet'; // Import Helmet
import theme from './theme';
import Menu from './components/organisms/Menu';
import MainContainer from './components/pages/MainContainer/MainContainer';
import { ActiveSectionProvider } from './contexts/ActiveSectionContext';
import styled from 'styled-components';
import { AppProvider, MultiDataProvider } from './contexts/DataContext';
import { HoverBlockProvider } from './contexts/HoverBlockContext';
import BackgroundImageEffect from './components/pages/MainContainer/BackgroundImageEffect';
import LoadingPage from './components/pages/LoadingPage/LoadingPage';
import LinkCircles from './components/molecules/LinkCircles';
import OpenToWorkSlider from './components/organisms/OpenToWorkSlider';
import { StatusBar } from 'expo-status-bar';
import { GuideProvider } from './contexts/GuideContext';
import GuideHelper from './components/organisms/GuideHelper';
import { PreviewProvider } from './contexts/ImageGalleryPreviewContext';
import ImageGalleryPreview from './components/molecules/ImageGalleryPreview';
import { OptimizerProvider } from './contexts/OptimizerContext';
import { AboutMeTilesProvider } from './contexts/UseAboutTilesContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import CookiesAgree from './components/organisms/CookiesAgree';

const Wrapper = styled.div`
  overflow: hidden;
`;

const isMobile = window.innerWidth <= 768;
const isSmallScreen = window.innerWidth <= 1200;

const App: React.FC = () => {
  return (
    <AnalyticsProvider>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <OptimizerProvider>
            <HoverBlockProvider>
              <MultiDataProvider>
                <ActiveSectionProvider>
                  <GuideProvider>
                    <PreviewProvider>
                      <LoadingProvider>
                        <AboutMeTilesProvider>
                          <Router>
                            <Wrapper>
                              {/* Helmet for Global Meta Tags */}
                              <Helmet>
                                <title>Michał Madejski | Web CV</title>
                                <meta name="description" content="Aesthetic, virtual, responsive Curriculum Vitae of Michał Madejski" />
                                <meta name="keywords" content="CV, Web Developer, Portfolio, React, Michał Madejski" />
                                <meta name="author" content="Michał Madejski" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta name="theme-color" content="#000000" />
                                {/* Open Graph Tags */}
                                <meta property="og:title" content="Michał Madejski | Web CV" />
                                <meta property="og:description" content="Aesthetic, virtual, responsive Curriculum Vitae of Michał Madejski" />
                                <meta property="og:image" content="https://cv.madejski.it/og-image.jpg" />
                                <meta property="og:url" content="https://cv.madejski.it/" />
                                <meta property="og:type" content="website" />
                                {/* Twitter Card Tags */}
                                <meta name="twitter:title" content="Michał Madejski | Web CV" />
                                <meta name="twitter:description" content="Aesthetic, virtual, responsive Curriculum Vitae of Michał Madejski" />
                                <meta name="twitter:image" content="https://cv.madejski.it/og-image.jpg" />
                              </Helmet>

                              <GuideHelper />
                              <CookiesAgree />
                              <StatusBar style="dark" />
                              <HoverBlockProvider>
                                <LoadingPage isFullPage />
                                <Menu />
                                {!isSmallScreen && <LinkCircles />}
                                {!isSmallScreen && <OpenToWorkSlider />}

                                <Routes>
                                  <Route path="*" element={<MainContainer />} />
                                </Routes>

                                <BackgroundImageEffect />
                              </HoverBlockProvider>
                              <ImageGalleryPreview />
                            </Wrapper>
                          </Router>
                        </AboutMeTilesProvider>
                      </LoadingProvider>
                    </PreviewProvider>
                  </GuideProvider>
                </ActiveSectionProvider>
              </MultiDataProvider>
            </HoverBlockProvider>
          </OptimizerProvider>
        </AppProvider>
      </ThemeProvider>
    </AnalyticsProvider>
  );
};

export default App;
