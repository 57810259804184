import React from 'react';
import { styled } from 'styled-components';
import { TileWrapper } from '../sections/Experience/Experience.styles';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import Typography from '../atoms/Typography';
import CustomButton from '../atoms/Button';

export const CookiesAgreeWrapper = styled.div`
  position: fixed;
  bottom: 8%;
  left: 4%;
  height: 520px;
  width: 500px;
  z-index: 100;

  @media (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;

    background-color: ${({ theme }) => theme.colors.greyDarkMidOpacity};

    // centralize all content
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledTileWrapper = styled(TileWrapper)`
  background-color: ${({ theme }) => theme.colors.greyDarkMidOpacity};
  padding: 12px 12px 12px 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.bgGreyDark};
  border: none;
  border-radius: 20px;
  box-shadow: 0px 0px 87px 10px rgba(0, 0, 0, 1);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyDark};
    transition: background-color 0.3s ease;
  }

  @media (max-width: 768px) {
    height: fit-content;
    width: 80vw;

    background-color: ${({ theme }) => theme.colors.greyDark};

  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
