import styled from 'styled-components';

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: -72px; // Adjust this value as needed to position the arrow correctly
  display: flex;
  align-items: center;

`;

export const SectionWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  padding-top: 10px;

  @media (max-width: 768px) {
    align-items: center;
    width: 92%;
    gap: 12px;
    height: 92%;
    margin-left: 4%;
    margin-top: 2%;
    padding-top: 5px;
  }
`;


export const EntityRow = styled.div<{ height?: number }>`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  gap: 25px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 8px 0;
  height: ${({ height }) => height}px;
  padding-right: 20px;

  @media (max-width: 726px) {
    overflow-x: auto;
    gap: 15px;
    padding-right: 15px;
    margin: 4px 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ExpandingWrapper = styled.div<{ is_row_section_active?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 5px;

`;


export const TileWrapper = styled.div < { is_row_section_active?: boolean; theme: any; centerOnBigScreen?: boolean } > `
  height: 100%;
  max-height: calc(100vh - 180px);
  border-radius: 10px;
  background-color: ${({ theme, is_row_section_active }: { theme: any; is_row_section_active?: boolean }) =>
    is_row_section_active ? theme.colors.greyPrimary35 : theme.colors.greyPrimary15};
  position: relative;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.greyPrimary40};
    border-radius: 4px;
  }

  &:hover {
    background-color: ${({ theme, is_row_section_active }: { theme: any; is_row_section_active?: boolean }) =>
      is_row_section_active ? theme.colors.greyPrimary35 : theme.colors.greyPrimary20};
    transition: background-color 0.3s ease-in-out;
  }

  @media (max-width: 726px) {
    min-width: 220px;
  }

  &::-webkit-scrollbar {
    margin: 3px;
    background-color: ${({ theme }: { theme: any }) => theme.colors.greyPrimary25};
    width: 10px;
    border-radius: 5px;

    &-thumb {
      background-color: ${({ theme }: { theme: any }) => theme.colors.greyPrimary40};
      border-radius: 5px;
    }
  }

  ${({ centerOnBigScreen }) =>
    centerOnBigScreen &&
    `
    @media (min-height: 1280px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const GeneralAboutSectionWrapper = styled.div`
  width: 35%;
  min-width: 300px;

  @media (max-width: 726px) {
    width: 45vw;
    min-width: unset;
    margin-right: 15px;
  }
`;

export const GeneralAboutSection = styled.div<{ is_row_section_active?: boolean }>`
  height: ${({ is_row_section_active }) => (is_row_section_active ? 'fit-content' : '100%')};
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin: 0;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.greyPrimary40};
    border-radius: 4px;
  }

  @media (max-width: 726px) {
    min-width: 160px;
    padding: 10px 10px 0 10px;
    gap: 12px;
  }

  &:hover {
    cursor: url('/src/assets/icons/expand_icon.svg'), auto;
  }

  ${({ is_row_section_active }) =>
    !is_row_section_active &&
    `&::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px; /* Height of the gradient */
    background: linear-gradient(
      to bottom,
      rgba(32, 32, 32, 0),
      rgba(32, 32, 32, 1)
    ); /* Gradient from transparent to opaque */
    pointer-events: none; /* Allows clicks to pass through */
  }'

  `}
`;
export const ImageWrapper = styled.div<{ height?: number; display?: boolean }>`
  width: ${({ display }) => (display ? '38%' : '0%')};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.55);

  @media (min-height: 1080px) {
    width: 50%;
  }
`;

export const MarkdownBlockWrapper = styled.div`
  height: 100%;
  width: 38%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-height: 1080px) {
    width: 50%;
  }

  @media (max-width: 726px) {
    margin-right: 20px;
  }

  @media (max-width: 576px) {
    width: 60%;
    min-width: 340px;
    margin-left: -20px;
    margin-right: 20px;
  }
`;

export const SSGalleryColumn = styled.div`
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
