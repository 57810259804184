import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { TileWrapper } from '../sections/Experience/Experience.styles';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';
import Typography from '../atoms/Typography';
import CustomButton from '../atoms/Button';
import { ButtonsWrapper, CookiesAgreeWrapper, StyledTileWrapper } from './CookiesAgree.styles';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router
import { GetCircleLinksData } from '../sections/Contact/Contact.data';

export default function CookiesAgree() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isAccepted, setIsAccepted] = useState<boolean>(() => {
    return localStorage.getItem('cookiesAccepted') === 'true';
  });
    
const isMobile = window.innerWidth <= 768;

  useLayoutEffect(() => {
    if (!isAccepted && wrapperRef.current) {
      // Initial stagger animation with delay
      gsap.fromTo(wrapperRef.current.children, 
        { opacity: 0, y: 20 }, // Initial state
        { opacity: 1, y: 0, stagger: 0.2, duration: 0.3, delay: isMobile ? 0 : 2.2 } // Final state with delay
      );
    }
  }, [isAccepted]);

  const handleAccept = () => {
    if (wrapperRef.current) {
      gsap.to(wrapperRef.current, {
        opacity: 0,
        y: 20,
        duration: 0.3,
        onComplete: () => {
          localStorage.setItem('cookiesAccepted', 'true');
          setIsAccepted(true);
        },
      });
    }
  };

  const handleReject = () => {
    window.location.href = 'https://www.linkedin.com/in/micha%C5%82-madejski-671b60134/';
  };

  if (isAccepted) {
    return null; // Don't render the component if cookies are accepted
  }
    
  const cvData = GetCircleLinksData()[3]; // Get CV download data

  return (
    <CookiesAgreeWrapper ref={wrapperRef}>
      <StyledTileWrapper>
      <Typography align="left" animated animationType="fade" variant={isMobile ? TypographyVariant.sectionHeaderMobile : TypographyVariant.sectionHeader} highlight_level={HighlightLevel.neutral}>
          Website Guide: Scroll Down
        </Typography>
        <Typography align="left" animated animationType="fade" variant={isMobile ? TypographyVariant.sectionSubHeaderMobile : TypographyVariant.sectionSubHeader} highlight_level={HighlightLevel.neutral}>
          If you only need my CV in PDF here you have:
        </Typography>
        <a 
          href={cvData.href}
          download={cvData.download}
          style={{ textDecoration: 'none' }}
        >
          <CustomButton 
            mode="secondary" 
            onClick={handleReject}
          >
            {cvData.icon}
            {cvData.info}
          </CustomButton>
        </a>
        <Typography align="left" animated animationType="fade" variant={isMobile ? TypographyVariant.sectionHeaderMobile : TypographyVariant.sectionHeader} highlight_level={HighlightLevel.neutral}>
          Cookies for a Better Experience
        </Typography>
        <Typography align="left" animated animationType="fade" variant={isMobile ? TypographyVariant.sectionSubHeaderMobile : TypographyVariant.sectionSubHeader} highlight_level={HighlightLevel.neutral}>
          Since this is my personal site, I’m legally obligated to let you know I use cookies—only to track how many awesome people have visited through analytics. No personal data
          is collected, just anonymous stats to help improve the site. You can choose to accept or reject these cookies below.
        </Typography>
        <ButtonsWrapper>
          <CustomButton mode="secondary" onClick={handleReject}>
            Reject
          </CustomButton>
          <CustomButton mode="primary" onClick={handleAccept}>
            Accept
          </CustomButton>
        </ButtonsWrapper>
      </StyledTileWrapper>
    </CookiesAgreeWrapper>
  );
}
