import styled from 'styled-components';
import Typography from '../atoms/Typography';

export const ArrowSectionWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 350px; // Increased width for more breathing room
  min-width: 350px;
  margin-right: 30px; // Add space between this and next element
  z-index: 999;

  @media (max-width: 768px) {
    width: 250px;
    min-width: 250px;
    margin-right: 15px;
  }
`;
export const StyledDate = styled(Typography)`
  font-size: 0.85em;
  text-align: center;
  width: 100%;
`;

export const PeriodLogoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 100%;
  padding: 0 20px;
  margin-right: 15px;

  @media (max-width: 768px) {
    width: 120px;
    padding: 0 10px;
    margin-right: 10px;
  }
`;

export const CompanyLogo = styled.img<{ src: string; alt?: string; magnified?: any }>`
  max-height: 60px;
  max-width: 60px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.whiteMidPrimary};
  transform: scale(${({ magnified }) => (magnified ? 1.25 : 1)});
  transition: transform 0.3s ease-in-out;

  @media (min-width: 768px) {
  &:hover {
    transform: scale(${({ magnified }) => (magnified ? 1.25 : 1)}) translateX(${({ magnified }) => (magnified ? '-10px' : '0')});
  }
  }

  @media (max-width: 768px) {
    max-height: 32px;
    max-width: 32px;
  }
`;

export const ArrowContainer = styled.div`
  height: 100%;
`;

export const StyledCompanyName = styled(Typography)`
  font-size: 0.95em;
  margin-bottom: 6px;
`;

export const StyledProjectName = styled(Typography)`
  font-size: 0.85em;
  margin-bottom: 4px;
`;

export const StyledPositionName = styled(Typography)`
  font-size: 1em;
`;

export const NameInArrowSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  gap: 4px;
  padding-left: 5px;

  @media (max-width: 768px) {
    max-width: 120px;
    min-width: 120px;
    padding-left: 3px;

`;
