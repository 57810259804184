import React, { useEffect, useRef, useState } from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';
import styled, { useTheme } from 'styled-components';
import MenuItem from '../molecules/MenuItem';
import { segmentDetails, SegmentKey } from '../../types/segments';
import { gsap } from 'gsap';
import { useActiveSection } from '../../contexts/ActiveSectionContext';
import { BurgerMenuContainer, HoverRectangle, MenuContainer, MenuItemWrapper, MobileSocialLinks, MobileSocialLinkItem, MobileSocialIcon, MobileSocialText } from './Menu.styles';
import LinkCircles from '../molecules/LinkCircles';
import { FaEnvelope, FaLinkedin, FaGithub, FaFileDownload } from 'react-icons/fa';
import { GetCircleLinksData } from '../sections/Contact/Contact.data';

// Convert segmentDetails to an array of menu data
const menuData = Object.keys(segmentDetails).map((key) => key as SegmentKey);

const Menu: React.FC = () => {
  const { activeSection, setActiveSection } = useActiveSection();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 900 || window.innerHeight <= 800); // Define mobile breakpoint
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close status
  const [isMounted, setIsMounted] = useState(false); // State to manage the mounting status of MenuContainer
  const menuContainerRef = useRef<HTMLDivElement | null>(null);
  const rectangleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (menuOpen && !isMobile) {
      setMenuOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const updateRectanglePosition = () => {
      const index = menuData.indexOf(activeSection as SegmentKey);
      if (rectangleRef.current && index !== -1) {
        gsap.to(rectangleRef.current, {
          y: index * 53, // Adjust based on the gap and height
          ease: 'elastic.out(1, 0.3)',
          duration: 0.25,
        });
      }
    };

    if (activeSection === 'PERSONAL') {
      if (isMounted) {
        // Fade out the menu and slide out the rectangle when returning to PERSONAL
        gsap.to(menuContainerRef.current, {
          opacity: 0,
          marginRight: -200,
          duration: 1,
          ease: 'power2.in',
          onComplete: () => setIsMounted(false), // Unmount after the animation
        });

        // Hide and slide out the HoverRectangle when returning to PERSONAL
        if (rectangleRef.current) {
          gsap.to(rectangleRef.current, {
            opacity: 0,
            marginRight: -200,
            duration: 0.25,
            ease: 'power2.out',
          });
        }
      }
    } else {
      if (!isMounted) {
        setIsMounted(true); // Mount the menu

        // Wait for the component to mount, then animate
        requestAnimationFrame(() => {
          gsap.fromTo(menuContainerRef.current, { opacity: 0, marginRight: -200 }, { opacity: 1, marginRight: 12, duration: 1.5, ease: 'power2.out' });
          updateRectanglePosition(); // Update rectangle position after menu is visible
        });
      } else {
        // Ensure rectangle position is updated if already mounted
        updateRectanglePosition();

        // Ensure the rectangle is visible and slides in with 12px offset if not returning to PERSONAL
        if (rectangleRef.current) {
          gsap.to(rectangleRef.current, {
            opacity: 1,
            marginRight: 8,
            duration: 0.25,
            ease: 'power2.in',
          });
        }
      }
    }
  }, [activeSection, isMounted]);

  const handleClick = (index: number, segmentKey: string) => {
    setActiveSection(segmentKey); // Update the active section in the context
    setMenuOpen(false); // Close the menu
  };

  // Define BurgerMenu styles using theme
  const burgerMenuStyles = {
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.8)', // Darker overlay with 0.8 opacity
    },
  };

  return (
    <>
      {!isMobile ? (
        isMounted && (
          <MenuContainer ref={menuContainerRef}>
            <HoverRectangle ref={rectangleRef} />
            {menuData.map((segmentKey, index) => (
              <MenuItemWrapper className={'menu-item'} key={segmentKey} onClick={() => handleClick(index, segmentKey)}>
                <MenuItem segmentKey={segmentKey} isActive={activeSection === segmentKey} />
              </MenuItemWrapper>
            ))}
          </MenuContainer>
        )
      ) : (
        <BurgerMenuContainer>
          <BurgerMenu right styles={burgerMenuStyles} isOpen={menuOpen} onStateChange={({ isOpen }) => setMenuOpen(isOpen)}>
            {menuData.map((segmentKey, index) => (
              <MenuItemWrapper className={'menu-item'} key={segmentKey} onClick={() => handleClick(index, segmentKey)}>
                <MenuItem segmentKey={segmentKey} isActive={activeSection === segmentKey} />
              </MenuItemWrapper>
            ))}
            
            <MobileSocialLinks>
              {GetCircleLinksData().map((item, index) => (
                <MobileSocialLinkItem key={index}>
                  <MobileSocialIcon 
                    href={item.href}
                    target={index === 1 || index === 2 ? '_blank' : undefined}
                    rel={index === 1 || index === 2 ? 'noopener noreferrer' : undefined}
                    download={item.download}
                  >
                    {item.icon}
                  </MobileSocialIcon>
                  <MobileSocialText>{item.info}</MobileSocialText>
                </MobileSocialLinkItem>
              ))}
            </MobileSocialLinks>
          </BurgerMenu>
        </BurgerMenuContainer>
      )}
    </>
  );
};

export default Menu;
