import React, { forwardRef } from 'react';
import { format } from 'date-fns';
import { ArrowSectionWrapper, PeriodLogoSection, CompanyLogo, NameInArrowSection, StyledCompanyName, StyledProjectName, StyledPositionName } from './ArrowSection.styles';
import Arrow from '../atoms/Arrow';
import Typography from '../atoms/Typography';
import { HighlightLevel, TypographyVariant } from '../atoms/Typography.autogen';

interface CompanyLogoAttributes {
  url: string;
}

interface CompanyLogoData {
  attributes: CompanyLogoAttributes;
}

interface ArrowSectionProps {
  index: number;
  isActive: boolean;
  getHighlightLevel: (index: number) => HighlightLevel;
  item: {
    start_date?: string;
    end_date?: string;
    company_logo?: {
      data: CompanyLogoData[];
    };
    company_name?: string;
    project_name?: string;
    position_name?: string;
  };
}

const ArrowSection = forwardRef<HTMLDivElement, ArrowSectionProps>(({ index, item, isActive, getHighlightLevel }, ref) => {
  const logoUrl = item.company_logo?.data?.[0]?.attributes?.url;
  const isMobile = window.innerWidth < 768;

  return (
    <ArrowSectionWrapper ref={ref} className="hoverable">
      <PeriodLogoSection>
        <Typography 
          align="center" 
          animated 
          animationType="fade" 
          variant={TypographyVariant.micro} 
          highlight_level={getHighlightLevel(index)} 
          capitalize
        >
          {item.end_date ? format(new Date(item.end_date), 'MMM yyyy').toUpperCase() : 'CURRENTLY'}
        </Typography>
        {logoUrl && <CompanyLogo src={logoUrl} alt="Company logo" magnified={isActive.toString()} />}
        <Typography 
          align="center" 
          animated 
          animationType="fade" 
          variant={TypographyVariant.micro} 
          highlight_level={getHighlightLevel(index)} 
          capitalize
        >
          {format(new Date(item.start_date || ''), 'MMM yyyy').toUpperCase()}
        </Typography>
      </PeriodLogoSection>
      <Arrow />
      <NameInArrowSection>
        <div>
          {item.company_name && (
            <StyledCompanyName 
              animated 
              animationType="fade" 
              highlight_level={getHighlightLevel(index)} 
              variant={TypographyVariant.sectionSubHeader}
            >
              {item.company_name}
            </StyledCompanyName>
          )}
          {item.project_name && (
            <StyledProjectName 
              animated 
              animationType="fade" 
              variant={TypographyVariant.micro} 
              highlight_level={getHighlightLevel(index)}
            >
              {item.project_name}
            </StyledProjectName>
          )}
          {item.position_name && (
            <StyledPositionName 
              animated 
              animationType="fade" 
              variant={TypographyVariant.micro} 
              highlight_level={getHighlightLevel(index)}
            >
              {item.position_name}
            </StyledPositionName>
          )}
        </div>
      </NameInArrowSection>
    </ArrowSectionWrapper>
  );
});

export default ArrowSection;
